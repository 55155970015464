<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { boolean, object, string } from 'yup'
import { PageType } from '#imports'

const { open, initialPage } = defineProps<{
  /**
   * Whether the modal is open.
   */
  open?: boolean
  /**
   * Inital page
   */
  initialPage?: Page
}>()
const emit = defineEmits(['close'])
const { t: $t } = useI18n({ useScope: 'global' })
const { t } = useI18n({ useScope: 'local' })

const schema = object({
  pageType: string().required().label($t('page.attributes.pageType')).oneOf(Object.values(PageType)).default(PageType.Default),
  title: string().required().label($t('page.attributes.title')),
  slug: string().label($t('page.attributes.slug')).defined(),
  showTitleOnPage: boolean()
    .nonNullable()
    .transform(value => !!value)
    .label($t('page.attributes.showTitleOnPage')),
  isPublished: boolean()
    .nonNullable()
    .transform(value => !!value)
    .label($t('page.attributes.isPublished')),
  layout: string()
    .required()
    .oneOf([PageLayout.Default, PageLayout.SideColumn])
    .label($t('page.attributes.pageLayout')),

  // Fields for news items
  summary: string().label($t('page.attributes.summary')).optional().nullable(),
  featured: boolean().label($t('page.attributes.featured')).default(false),
  headerImage: imageUploadField.label($t('page.attributes.headerImage')),

  // Fields for SEO
  metaDescription: string().optional().label($t('page.attributes.meta_description')).nullable(),
  metaTitle: string().optional().label($t('page.attributes.meta_title')).nullable(),
  ogDescription: string().optional().label($t('page.attributes.og_description')).nullable(),
  // og_image: string().required().label($t('page.attributes.og_image')), // TODO
  ogTitle: string().optional().label($t('page.attributes.og_title')).nullable(),
})

const { handleSubmit, isSubmitting, resetForm, setFieldError, setFieldValue, values }
  = useForm({
    validationSchema: toTypedSchema(schema),
    initialValues: initialPage || {
      pageType: PageType.Default,
      // title: '',
      // slug: '',
      showTitleOnPage: true,
      isPublished: false,
      layout: PageLayout.Default,
    },
  })

// When adding a new page, automatically generate the slug from the title
watch(() => values.title, (newTitle) => {
  console.log('newTitle', newTitle)
  if (newTitle && !initialPage) {
    // Replace spaces with dashes and remove non-alphanumeric characters
    setFieldValue('slug', newTitle.toLowerCase().replace(/[^a-z0-9]/g, '-'))
  }
})

const { handleServerError } = useServerErrorHandler(schema, setFieldError)
const { handleValidationError } = useFormValidationErrorHandler()

const submit = handleSubmit(async (values) => {
  try {
    if (!initialPage) {
      await createPage(values)
    }
    else {
      // Quickfix for boolean fields
      // TODO: fix the CheckboxField component to emit false values
      values = {
        ...values,
        showTitleOnPage: !!values.showTitleOnPage,
        isPublished: !!values.isPublished,
      }

      await updatePage(initialPage.id, values)

      // If the slug has been changed and we're on a path that includes the slug,
      // replace the slug and navigate to that page.
      const currentRoute = useRoute().fullPath
      if (
        initialPage.slug !== values.slug
        && currentRoute.includes(initialPage.slug)
      ) {
        navigateTo(currentRoute.replace(initialPage.slug, values.slug || ''))
        return
      }
    }

    emit('close')
    resetForm()
  }
  catch (error) {
    console.log(`Form error`, error)
    handleServerError(error)
  }
}, handleValidationError)

const pageLayoutItems = Object.values(PageLayout)
// .map((pageLayout) => ({
//   value: pageLayout,
//   label: pageLayout,
// }))
</script>

<template>
  <BaseModal
    :open="open"
    size="lg"
    @close="$emit('close')"
  >
    <template #title>
      {{
        initialPage ? t('editHeadingTitle') : t('headingTitle')
      }}
    </template>
    <BaseParagraph>
      {{
        initialPage ? t('editHeadingText') : t('headingText')
      }}
    </BaseParagraph>

    <FormBase>
      <FormListField
        name="pageType"
        :label="$t('page.attributes.pageType')"
        :items="Object.values(PageType)"
      />

      <FormField name="title" :label="$t('page.attributes.title')" />
      <FormField name="slug" :label="$t('page.attributes.slug')" />

      <Field v-slot="{ field, errorMessage }" name="layout">
        
        <BaseListbox
          :items="pageLayoutItems"
          :label="$t('page.attributes.pageLayout')"
          :error="errorMessage"
          v-bind="field"
        />
        {{ errorMessage }}
      </Field>

      
      <FormCheckboxField name="showTitleOnPage" :label="$t('page.attributes.showTitleOnPage')" />
      
      <FormCheckboxField name="isPublished" :label="$t('page.attributes.isPublished')" />

      <template v-if="values.pageType === PageType.News || values.pageType === PageType.SuccessStory">
        <FormTextarea name="summary" :label="$t('page.attributes.summary')" />
        
        

        <img
          v-if="initialPage?.headerImageUrl"
          :src="initialPage.headerImageUrl"
          alt="Header image"
        >
        <FormImageField name="headerImage" :label="$t('page.attributes.headerImage')" />
      </template>

      <FormField name="metaTitle" :label="$t('page.attributes.meta_title')" />
      <FormField name="metaDescription" :label="$t('page.attributes.meta_description')" />
      <!-- <FormField name="ogDescription" :label="$t('page.attributes.og_description')" />
      <FormField name="ogTitle" :label="$t('page.attributes.og_title')" /> -->

      
    </FormBase>

    <template #buttons>
      <BaseButton @click="$emit('close')">
        {{ $t('cancel') }}
      </BaseButton>

      <SubmitButton :is-submitting="isSubmitting" @click="submit">
        {{
          $t('save')
        }}
      </SubmitButton>
    </template>
  </BaseModal>
</template>

<i18n lang="json">
{
  "en": {
    "headingTitle": "Add a page",
    "headingText": "Add a new page",
    "editHeadingTitle": "Edit page",
    "editHeadingText": "Edit the settings of this page."
  },
  "nl": {
    "headingTitle": "Pagina toevoegen",
    "headingText": "Voeg een nieuwe pagina toe.",
    "editHeadingTitle": "Pagina bewerken",
    "editHeadingText": "Bewerk de instellingen van deze pagina."
  }
}
</i18n>
